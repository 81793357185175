
import InfoSectionCard from "./InfoSectionCard.vue";

export default {
  name: "InfoSection",
  components: {
    InfoSectionCard,
  },
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasCards() {
      return this.blok.cards.length > 0;
    },
    containerClasses() {
      return [
        { "md:flex-row-reverse": this.blok.reverse },
        this.hasCards ? "md:items-start" : "md:items-center",
      ];
    },
  },
};
