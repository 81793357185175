
export default {
  name: "InfoSectionCard",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
