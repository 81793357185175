import { render, staticRenderFns } from "./index.vue?vue&type=template&id=21aa57ee&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=21aa57ee&prod&lang=scss"
import style1 from "./index.vue?vue&type=style&index=1&id=21aa57ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21aa57ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoSectionCard: require('/opt/build/repo/components/common/InfoSection/InfoSectionCard.vue').default,AppButton: require('/opt/build/repo/components/common/AppButton.vue').default})
